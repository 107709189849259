.landing-page .container {
  padding: 0 1em;
}
.landing-page h1 {
  font-size: 2.5em;
}
.landing-page h2 {
  font-size: 2.25em;
}
.landing-page h3 {
  font-size: 1.2em;
  text-transform: uppercase;
}
.landing-page .hero-body {
  padding: 3.5rem 0;
}
.landing-page .column {
  padding: 1em;
}
.landing-page.content {
  margin-bottom: 0;
}
.landing-page.content p {
  font-size: 20px;
}
.landing-page.content p a.button {
  font-size: 1em;
  margin-top: 0.5em;
}
.landing-page .logo {
  margin-left: -28px;
}
.landing-page .logo a {
  margin-top: 0;
}
.landing-page .hero a {
  font-family: EmbeddedTitleFont, sans-serif;
  font-size: 19px;
  white-space: nowrap;
  display: block;
  padding-top: 1px;
}
.landing-page .hero a.sign-in {
  display: block;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  color: rgb(74, 74, 74);
}
.landing-page .hero a.sign-in:hover {
  color: black;
}
.landing-page section {
  padding-top: 3em;
  padding-bottom: 3em;
}
.landing-page section.hero {
  padding: 0;
}
.landing-page section.dark {
  background-color: #505050;
  color: white;
}
.landing-page section.dark h2 {
  color: white;
}
.landing-page section.alternative {
  background-color: #fafafa;
}
.landing-page section.border-top {
  border-top: 1px solid #ddd;
}
.landing-page .overview-card {
  background-color: #DAE8EE;
  border: 1px solid #999999;
  border-radius: 8px;
  overflow: hidden;
}
.landing-page .overview-card .overview-card-image {
  padding: 0.5em;
}
.landing-page .overview-card .overview-card-title {
  background-color: white;
  border-top: 1px solid #999999;
  text-align: center;
  color: #666;
  padding: 0.5em;
  font-size: 20px;
  font-family: EmbeddedTitleFont, sans-serif;
}
.landing-page .overview-card .overview-card-title p:not(:last-child) {
  margin-bottom: 0.25em;
}
.landing-page .overview-card .template-description {
  font-size: 16px;
  font-family: BodyFont, sans-serif;
}
.landing-page a.overview-card {
  display: block;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
.landing-page a.overview-card:hover, .landing-page a.overview-card:focus, .landing-page a.overview-card:active {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.6);
}
.landing-page section.dark .overview-card {
  border: none;
}
.landing-page .hero-thumbnail {
  border: 1px solid #ddd;
}