.landing-page {
  // Give some padding on mobile
  .container {
    padding: 0 1em;
  }

  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2.25em;
  }
  h3 {
    font-size: 1.2em;
    text-transform: uppercase;
  }

  .hero-body {
    padding: 3.5rem 0;
  }

  .column {
    padding: 1em;
  }

  &.content {
    margin-bottom: 0;
    p {
      font-size: 20px;
      a.button {
        font-size: 1em;
        margin-top: 0.5em
      }
    }
  }

  .logo {
    margin-left: -28px;
    a {
      margin-top: 0;
    }
  }

  .hero {
    a {
      font-family: EmbeddedTitleFont, sans-serif;
      font-size: 19px;
      white-space: nowrap;
      display: block;
      padding-top: 1px;
      &.sign-in {
        display: block;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        color: rgb(74, 74, 74);
        &:hover {
          color: black;
        }
      }
    } 
  }

  section {
    padding-top: 3em;
    padding-bottom: 3em;

    &.hero {
      padding: 0;
    }

    &.dark {
      background-color: #505050;
      color: white;
      h2 {
        color: white;
      }
    }

    &.alternative {
      background-color: #fafafa;
    }

    &.border-top {
      border-top: 1px solid #ddd;
    }
  }

  .overview-card {
    background-color: #DAE8EE;
    border: 1px solid #999999;
    border-radius: 8px;
    overflow: hidden;

    .overview-card-image {
      padding: 0.5em;
    }

    .overview-card-title {
      background-color: white;
      border-top: 1px solid #999999;
      text-align: center;
      color: #666;
      padding: 0.5em;
      font-size: 20px;
      font-family: EmbeddedTitleFont, sans-serif;
      p:not(:last-child) {
        margin-bottom: 0.25em;
      }
    }

    .template-description {
      font-size: 16px;
      font-family: BodyFont, sans-serif;
    }
  }

  a.overview-card {
    display: block;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    &:hover, &:focus, &:active {
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.6);
    }
  }

  section.dark {
    .overview-card {
      border: none;
    }
  }

  .hero-thumbnail {
    border: 1px solid #ddd;
  }
}